<template>
  <div style="background-color: #00A8BB">
  <v-container
    fill-height
    fluid
    grid-list-xl
  >
    <v-container
        fluid
        grid-list-lg
    >
      <v-layout
          justify-center
          wrap
      >
        <v-flex
            xs12
            md4
        >
            <v-card>
              <v-card-title>Seus Dados</v-card-title>
              <v-card-text>
                  <v-container py-0>
                    <v-layout wrap>
                      <v-flex
                        xs12
                      >
                          <ul style="list-style-type:none;">
                            <li><span class="list">Nome:</span> {{ lead.name }}</li>
                            <li><span class="list">CPF:</span> {{ lead.cpf }}</li>
                            <li><span class="list">E-mail:</span> {{ lead.email }}</li>
                            <li><span class="list">No. Cliente:</span> {{ lead.numCliente }}</li>
                            <li><span class="list">No. Instalação:</span> {{ lead.numInstalacao }}</li>
                            <li><span class="list">Endereço:</span> {{ lead.endereco }}, {{ lead.numero }} <span v-if="!lead.complemento || lead.complemento != ''">({{ lead.complemento }})</span>,
                        {{ lead.bairro }} - {{ lead.cidade }}/{{ state.sigla }} - CEP: {{ lead.cep }}</li>
                          </ul>
                      </v-flex>
                    </v-layout>
                  </v-container>
              </v-card-text>
            </v-card>
        </v-flex>

        <v-flex
            xs12
            md4
        >
            <v-card>
              <v-card-title>Proposta</v-card-title>
              <v-card-text>
                  <v-container py-0>
                    <v-layout wrap>
                      <v-flex
                        xs12
                      >
                        <ul style="list-style-type:none;">
                          <li><span class="list">Créditos:</span> R$ {{ contrato.creditos }}</li>
                          <li><span class="list">Mensalidade:</span> R$ {{ contrato.mensalidade }}</li>
                          <li><span class="list">Desconto Total Contratual:</span> R$ {{ contrato.descCont }}</li>
                        </ul>
                      </v-flex>
                    </v-layout>
                  </v-container>
              </v-card-text>
            </v-card>
        </v-flex>

        <v-flex
            xs12
            md4
        >
            <v-card>
              <v-card-title>Dados Complementares</v-card-title>
              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                  <v-container py-0>
                    <v-layout wrap>
                      <v-flex
                        xs12
                      >
                        <v-text-field
                          v-model="lead.birthday"
                          label="Data de Nascimento"
                          :hint="'Formato dd/mm/aaaa'"
                          :rules="[v => /^([0-2][0-9]|(3)[0-1])(((0)[0-9])|((1)[0-2]))\d{4}$/.test(v) || 'Data de nascimento inválida!']"
                          mask="##/##/####"
                          prepend-icon="date_range"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex
                        xs12
                      >
                        <v-text-field
                          v-model="lead.document_id"
                          label="Documento de Identidade"
                          :rules="[v => (!!v || '') || 'Por favor, insira o número da identidade!',
                                    v => /[\S]+/.test(v) || 'Número de identidade inválido!']"
                          prepend-icon="person"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex
                        xs12
                      >
                        <v-text-field
                            v-model="lead.dispatching_agency"
                            label="Órgão Expeditor"
                            :rules="[v => (!!v || '') || 'Por favor, insira o órgão expedidor!',
                                      v => /[\S]+/.test(v) || 'Órgão Expeditor inválido!']"
                            prepend-icon="account_balance"
                            required
                        ></v-text-field>
                      </v-flex>
                      <v-flex
                        xs12
                      >
                        <v-text-field
                          v-model="lead.nationality"
                          label="Nacionalidade"
                          :rules="[v => (!!v || '') || 'Por favor, insira a nacionalidade!',
                                    v => /[\S]+/.test(v) || 'Nacionalide inválida!']"
                          prepend-icon="public"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex
                        xs12
                      >
                        <v-select
                          v-model="marital_status"
                          label="Estado Civil"
                          :rules="[v => (!!v || '') || 'Por favor, selecione o estado civil!']"
                          :items="estadoCivil"
                          prepend-icon="people"
                          required
                        ></v-select>
                      </v-flex>
                      <v-flex
                        xs12
                      >
                        <v-select v-if="marital_status == 'Casado(a)'"
                          v-model="lead.property_regime"
                          :rules="rulesMarital"
                          label="Regime de Bens"
                          :items="regimeBens"
                          prepend-icon="people"
                          required
                        ></v-select>
                      </v-flex>
                      <v-flex
                        xs12
                      >
                        <v-text-field
                          v-model="lead.profession"
                          label="Profissão"
                          :rules="[v => (!!v || '') || 'Por favor, insira a profissão!',
                                    v => /[\S]+/.test(v) || 'Profissão inválida!']"
                          prepend-icon="work"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md12 style="text-align: right">
                        <v-btn
                          :disabled="!valid"
                          color="success"
                          @click="validate"
                          flat
                          large
                        >
                          CONTINUAR
                        </v-btn>
                    </v-flex>
                    </v-layout>
                  </v-container>
                </v-form>
                <v-progress-circular
                  v-if="processing"
                    :size="50"
                    color="teal"
                    indeterminate
                ></v-progress-circular>
              </v-card-text>
            </v-card>
        </v-flex>

        <v-snackbar
        :color="color"
        :bottom="bottom"
        :top="top"
        :left="left"
        :right="right"
        v-model="snackbar"
        dark
        >
        <v-icon
            color="white"
            class="mr-3"
        >
            mdi-bell-plus
        </v-icon>
        <div>{{snackMessage}}</div>
        <v-icon
            size="16"
            @click="snackbar = true"
        >
            mdi-close-circle
        </v-icon>
        </v-snackbar>
    </v-layout>
    </v-container>
  </v-container>
  </div>
</template>
<script>
import { getLead, updateLead } from '../../services/leads'
import { firebaseAuth } from '../../firebase/firebaseAuth'

export default {
  data: () => ({
    valid: false,
    lead: {},
    marital_status: '',
    state: {},
    contrato: {},
    loading: false,
    color: null,
    colors: [
      'purple',
      'info',
      'success',
      'warning',
      'error'
    ],
    top: true,
    bottom: false,
    left: false,
    right: false,
    snackMessage: '',
    snackbar: false,
    x: 0,
    y: 0,
    estadoCivil: ['Solteiro(a)', 'Casado(a)', 'Viúvo(a)', 'Separado(a) Judicialmente', 'Divorciado(a)'],
    regimeBens: ['Comunhão Parcial', 'Comunhão Universal', 'Participação Final nos Aquestos', 'Separação de Bens'],
    processing: false,
    /*rules: {
      birthday: {
        required: v => !!v || 'Por favor, insire uma data de nascimento!',
        regex: v => /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/.test(v) || 'Data de nascimento inválida!'
      }
    }*/
  }),
  beforeDestroy () {
    this.processing = false;
  },
  methods: {
    validate () {
      if (this.$refs.form.validate()) {
        this.finalizar(this.lead)
      }
    },
    finalizar: function(lead) {
      this.processing = true;
      lead.marital_status = this.marital_status;
      lead.document_id = lead.document_id.trim()
      lead.dispatching_agency = lead.dispatching_agency.trim()
      lead.nationality = lead.nationality.trim()
      lead.profession = lead.profession.trim()
      updateLead(this.$route.query.email, lead)
        .then((result) => {
          if(result.data){
            firebaseAuth.signInAnonymously()
              .catch(function(error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                if (errorCode === 'auth/operation-not-allowed') {
                  console.error('Você deve ativar autenticação anônima no Console Firebase.', error);
                } else {
                  console.error(errorMessage,  error);
              }
            });
            firebaseAuth.onAuthStateChanged(function(user) {
              if (user) {
                // User is signed in.
                // console.log('User teste', user)
                localStorage.setItem('user', JSON.stringify(user))
                // console.log('token: ', user.ra)
                localStorage.setItem('token', user.ra)
                //localStorage.setItem('refresh-token', JSON.stringify(user.stsTokenManager.refreshToken))
                // console.log('Anon sign in successful!')
              } else {
                // User is signed out.
                // ...
              }
            });
            this.processing = false
            localStorage.setItem('lead', JSON.stringify(lead))
            this.$router.replace('cadastro-assinar')
          }
        })
    },
    snack (message, color, ...args) {
      this.top = false
      this.bottom = false
      this.left = false
      this.right = false

      for (const loc of args) {
        this[loc] = true
      }

      this.color = color  
      this.snackMessage = message
      this.snackbar = true
    },
    async getLeadDetail() {
      if(this.$route.query.email){
        this.loading = true
        getLead(this.$route.query.email)
          .then((result) => {
            if(result.data) {
              this.lead = result.data;
              this.state = this.lead.state;
              this.contrato = this.lead.contrato;
              if(this.lead.marital_status) this.marital_status = this.lead.marital_status;
              if(!this.lead.property_regime) this.lead.property_regime = 'N/A';
              this.loading = false;
            }
          })
          .catch(err => {
            console.error('Error: ', err);
            this.loading = false;
          })
      }
    },
    async update(select_lead) {
      await updateLead(select_lead.email, select_lead)
        .then(() => {
          this.$nextTick(() => {
            this.snack('Dado(s) atualizado(s) com sucesso!','green','top')
          })
        })
        .catch(error => {
          console.error('Erro ao atualizar os dados. ', error)
          this.snack('Erro ao atualizar o(s) dado(s).','error','top')
        })
    },
  },
  computed: {
    rulesMarital() {
      const rules = []

      if(this.marital_status == 'Casado(a)'){
        const rule = v => (!!v && v) != 'N/A' || 'Por favor, selecione a separação de bens!'
        rules.push(rule)
      }
      
      return rules
    },
  },
  beforeMount() {
  },
  mounted() {
    this.getLeadDetail();
  },
  watch: {
    marital_status: function(valor) {
      if(valor != 'Casado(a)') this.lead.property_regime = 'N/A';
    },
  },
  filters: {
    formatStep: function (value) {
      var step_text = '';
      if (!value) return ''
      
      if (value === '0') {
        step_text = 'Sem contas'
      } else if (value === '1') {
        step_text = 'Gerando propostas'
      } else {
        step_text = 'error'
      }
      return step_text
    },
  }
}
</script>
<style scoped>
.zoom {
  transition: transform .2s;
  margin: 0 auto;
}

.zoom:hover {
  -ms-transform: scale(1.4); /* IE 9 */
  -webkit-transform: scale(1.4); /* Safari 3-8 */
  transform: scale(1.4); 
}

.list {
  font-weight: bold;
  font-size: 1em;
}
</style>
